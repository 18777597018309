// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-artists-js": () => import("./../../../src/pages/artists.js" /* webpackChunkName: "component---src-pages-artists-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-exhibitions-js": () => import("./../../../src/pages/exhibitions.js" /* webpackChunkName: "component---src-pages-exhibitions-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-viewing-rooms-js": () => import("./../../../src/pages/viewing-rooms.js" /* webpackChunkName: "component---src-pages-viewing-rooms-js" */),
  "component---src-templates-announcement-js": () => import("./../../../src/templates/announcement.js" /* webpackChunkName: "component---src-templates-announcement-js" */),
  "component---src-templates-artist-js": () => import("./../../../src/templates/artist.js" /* webpackChunkName: "component---src-templates-artist-js" */),
  "component---src-templates-artwork-js": () => import("./../../../src/templates/artwork.js" /* webpackChunkName: "component---src-templates-artwork-js" */),
  "component---src-templates-exhibition-js": () => import("./../../../src/templates/exhibition.js" /* webpackChunkName: "component---src-templates-exhibition-js" */),
  "component---src-templates-newsletter-js": () => import("./../../../src/templates/newsletter.js" /* webpackChunkName: "component---src-templates-newsletter-js" */),
  "component---src-templates-private-room-js": () => import("./../../../src/templates/privateRoom.js" /* webpackChunkName: "component---src-templates-private-room-js" */),
  "component---src-templates-publication-js": () => import("./../../../src/templates/publication.js" /* webpackChunkName: "component---src-templates-publication-js" */),
  "component---src-templates-viewing-room-js": () => import("./../../../src/templates/viewingRoom.js" /* webpackChunkName: "component---src-templates-viewing-room-js" */)
}

